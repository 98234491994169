<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import ProvincesData from "./provinces-data";
import AddProvince from "./add-province"

import {
  layoutComputed,
  authComputed,
  provincesMethods,
  provincesComputed,
  regionsMethods,
  regionsComputed,
} from "@/state/helpers";
/**
 * Provinces component
 */
export default {
  page: {
    title: "Provinces",
    meta: [{ name: "Provinces" }],
  },
  components: {
    Layout,
    PageHeader,
    ProvincesData,
    AddProvince,
  },
  async mounted() {
    await this.retrieveProvinces();
    await this.retrieveRegions();
  },
  data() {
    return {
      title: "titles.provinces.text",
      

      busy: false,

      provincesData: [],
      regionsData: [],
    };
  },
  computed: {
    ...layoutComputed,
    ...authComputed,
    ...provincesComputed,
    ...regionsComputed,

    items(){
      return [
        {
          text: "Nextrack",
        },
        {
          text: this.$t("titles.provinces.text"),
          active: true,
        },
      ]
    },

    userRole(){
      return this.loggedIn.user.role
    }
  },
  methods: {
    ...provincesMethods,
    ...regionsMethods,

    retrieveProvinces() {
      if (this.getProvinces.results) {
        this.provincesData = this.getProvinces.results;
      } else {
        this.busy = true;
        return this.getProvincesBy({ limit: 100 }).then((provinces) => {
          this.busy = false;
          return provinces;
        });
      }
    },
    retrieveRegions() {
      if (this.getRegions.results) {
        this.regionsData = this.getRegions.results;
      } else {
      return this.getRegionsBy({ limit: 100 }).then((regions) => {
          return regions;
        });
      }
    },

    openAdd() {
      this.showModalAdd();
    },

    showModalAdd() {
      this.$bvModal.show("add-province-modal");
    },
    hideModalAdd() {
      this.$bvModal.hide("add-province-modal");
    },

    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },

    add(province) {
      this.addProvince(province)
        .then((province) => {
          this.makeToast(
            "Add province",
            province.name + " has been added",
            "success"
          );
        })
        .catch((error) => {
          this.makeToast("Add province", error, "danger");
        });
    },

    cancel() {
      return;
    },
    cleanObject(obj) {
      for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName];
        }
      }
      return obj;
    },
  },
  watch: {
    getProvinces(newVal) {
      this.provincesData = newVal.results;
    },
    getRegions(newVal){
      this.regionsData = newVal.results;
    }
  },
  beforeDestroy() {},
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t(title)" :items="items" />
    <div v-if="['SYSADMIN', 'ADMIN', 'MANAGER'].includes(userRole)" class="row">
      <div class="col-12 text-right">
        <b-button type="button" class="mr-2" variant="info" @click="openAdd"
          >{{$t("buttons.addProvince.text")}}
        </b-button>
        <AddProvince @addProvince="add" :regions="regionsData" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ProvincesData v-bind:provinces="provincesData" :regions="regionsData" :busy="busy" />
      </div>
    </div>
  </Layout>
</template>