<template>
  <div>
    <b-modal
      id="edit-province-modal"
      ref="edit-province-modal"
      size="md"
      @hidden="cancel"
      :title="$t('modals.updateProvince.text')"
    >
      <!-- Name -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="name">{{ $t("modals.name.text") }}</label>
          <b-form-input
            id="name"
            v-model="provinceEdit.name"
            class="mb-1"
            :placeholder="$t('modals.name.text')"
            :state="err.name ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.name }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <!-- Region -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="region">{{ $t("modals.region.text") }}</label>
          <b-form-select
            id="region"
            v-model="provinceEdit.region"
            :options="regionsData"
            class="mb-1"
            :state="err.region ? false : null"
          ></b-form-select>
          <b-form-invalid-feedback>
            {{ err.region }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="row p-2">
          <div class="col-10 text-right p-1">
            <b-button variant="success" class="text-white" @click="edit(ok)">
              {{ $t("buttons.update.text") }}
            </b-button>
          </div>
          <div class="col-2 text-center p-1">
            <b-button @click="cancel()">
              {{ $t("buttons.cancel.text") }}
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ["province", "regions"],
  created() {},
  data() {
    return {
      provinceEdit: {
        provinceId: null,
        name: null,
        region: null,
      },
      err: {
        name: null,
        region: null,
      },
      regionsData: [{ value: null, text: this.$t("titles.regions.text"), disabled: true }],
    };
  },
  watch: {
    province(newVal) {
      this.provinceEdit = {
        provinceId: newVal.id,
        region: newVal.region || null,
        name: newVal.name,
      };
    },
    regions(newVal){
      let regionsList = newVal;
      regionsList.map((obj) => {
          this.regionsData.push({ value: obj.id, text: obj.name });
      });
    }
  },
  methods: {
    cancel() {
      this.clear();
    },
    clear() {
      /* this.provinceEdit = {
        provinceId: null,
        name: null,
      }; */
      this.err = {
        name: null,
        region: null,
      };
    },
    edit(ok) {
      if (!this.provinceEdit.name)
        this.err.name = this.$t("msgError.obligField.text");
      else this.err.name = "";

      if (!this.err.name) {
        if ((this.provinceEdit.name != this.province.name) || (this.provinceEdit.region != this.province.region)) {
          ok();
          this.$emit("editProvince", this.provinceEdit);
        } else {
          ok();
        }
      }
    },
  },
};
</script>